<template>
  <!--质押弹窗-->
  <div class="Pledge">
    <van-dialog
      v-model="$store.state.pledgeShow"
      show-cancel-button
      :showConfirmButton="false"
      :showCancelButton="false"
      title=""
    >
      <div class="wrap">
        <div class="sec1">
          <div class="d1">{{lang('zhiya')}}sTFT</div>
          <img
            @click="hide"
            class="img1"
            src="../../assets/img/btn_close2x.png"
          />
          <div class="d2 mb15">
            <!-- <div class="d3">{{ lang("toupiaoshuliang") }}</div> -->
            <div class="d3">
              {{ lang("a_keyong") }}：
              <div class="d4">{{ BigNumberStr(snftNum,0,3) }}sTFI</div>
            </div>
          </div>
          <div class="d5">
            <!--  @click="voteAmountShow"  v-model="voteAmount"-->
            <input  placeholder="" v-model="pledgeAmount"/>
            <div class="d6" @click="pledgeAmountShow">
              {{ lang("a_zuida") }}
            </div>
          </div>

          <div class="d8">
            <button class="active .cursor-pointer" @click="submit">
              {{ lang("queding") }}
            </button>
            <!-- <button class="active1" v-else>{{lang('a_queren')}}</button> -->
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
  import {lang,Toast,} from "../../tools";
  import * as config from "../../config";
  import * as metaMaskTools from "../../tools/chain";
  import { mixin1, mixin2, mixin3 } from "../../tools/mixins";
  import { busEvent } from '../busEvent.js'

  export default {
    name: 'Pledge',
    data(){
      return {
        depositVal: '',
        tabIndex: 0,
        snftNum:0,
        pledgeAmount:''
      }
    },
    computed: {
    account() {
      return this.$store.state.accounts[0];
    },
    },
    mixins: [mixin1, mixin2, mixin3],
    methods: {
       initPage() {
        this.getbalanceOfSNFT()
      },
      initData() {
        this.getCountdown()
      },
      onRefresh(accounts) {
         this.initPage()
      },
      lang(key){
        return lang(key);
      },
      async getbalanceOfSNFT() {
        var res = await metaMaskTools.getbalanceOfSNFT(this.account);
        this.snftNum = this.BigNumberStr(res, 18);
      },
      pledgeAmountShow() {
        this.pledgeAmount=this.snftNum
        this.pledgeAmount=this.BigNumberStr(this.pledgeAmount,0,3)
      },
      hide() {
        this.$store.commit('setState', {
          pledgeShow: false
        })
      },
      async submit(){
        if(this.pledgeAmount=='' ||this.pledgeAmount<0  ) {
          Toast(this.lang('qingshuruzhengqueshuzhi'))
          return
        } else {
          if(this.voteAmount/1>this.nftNum/1) {
          Toast(this.lang('zhanghuyuebuzu'))
          return
        } else {
          var res=await metaMaskTools.getSNFT(this.account, this.pledgeAmount);
          Toast(this.lang('zhiyachenggong'));
          this.getbalanceOfSNFT()
          this.pledgeAmount=''
          this.hide();
          busEvent.$emit('getBusPle',true)
        }
        }
         
      },
    },
  }

</script>

<style lang="less">
  .Pledge {
  letter-spacing: 3px;

  .van-dialog {
    width: 360px;
  }

  .wrap {
    > .sec1 {
      background: #152e55;
      border-radius: 4px;
      padding: 0 30px 30px;
      position: relative;
      height: auto;
      overflow: hidden;
      border: 0;
      > .img1 {
        width: 12px;
        height: 12px;
        cursor: pointer;
        position: absolute;
        right: 25px;
        top: 20px;
      }

      .d1 {
        font-weight: 600;
        font-size: 14px;
        color: #fff;
        height: 70px;
        line-height: 70px;
        text-align: center;
        margin: 30px 0 20px;
      }

      .d2 {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      .d3 {
        font-size: 12px;
        color: #ccc;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      .d4 {
        color: #fff;
      }
      .d5 {
        position: relative;
        margin-bottom: 10px;
        input {
          width: 100%;
          height: 50px;
          line-height: 50px;
          font-size: 24px;
          color: #fff;
          padding: 0 20px;
          border: solid 1px #0f111f;
          background: #0f111f;
          border-radius: 10px;
        }
      }

      .d6 {
        color: #fff;
        font-size: 12px;
        background: linear-gradient(#0077c5, #0045bb);
        text-align: center;
        padding: 4px 12px;
        position: absolute;
        right: 10px;
        top: 13px;
        border-radius: 8px;
        cursor: pointer;
      }

      .d7 {
        font-size: 14px;
        color: #fff;
        position: absolute;
        right: 20px;
        top: 21px;
      }

      .d8 {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        // button {

        // }

        button.active {
          width: 100px;
          height: 25px;
          border: 0;
          text-align: center;
          line-height: 25px;
          font-size: 12px;
          color: #fff;
          background: linear-gradient(#0077c5, #0045bb);
          border-radius: 10px;
          margin: 0 18px;
        }

        // button.active1 {
        //   background: linear-gradient(#0077c5, #0045bb);
        //   border: 0;
        //   color: #fff;
        //   border-radius: 10px;
        // }
      }
    }
  }
}

  @media (max-width: 768px) {
    .Pledge {
      .van-dialog {
        width: 300px;
      }

      .wrap {
        > .sec1 {
          padding: 0 20px 15px;

          .img1 {
            right: 21px;
            top: 16px;
          }

          .d1 {
            padding: 0 0 0 20px;
            height: 45px;
            line-height: 45px;
            font-size: 13px;
            margin-bottom: 10px;
          }

          .d2{
            margin-bottom: 10px;
          }

          .d2_1{
            margin-bottom: 25px;
          }

          .d5 {
            position: relative;
            background: #17191C;
            margin-bottom: 10px;

            input {
              height: 40px;
              line-height: 40px;
              padding: 0 10px;
            }
          }
          .d6 {
            top: 8px;
          }
          .d7 {
            top: 11px;
          }
          .d6,.d7,.d8,.d9,.d10{
            font-size: 10px;
          }
          .d8{
            button{
              height: 40px;
              line-height: 40px;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
  .line_tc {
    margin-bottom: 20px;
    background: #fff;
    height: 1px;
  }
</style>
