<template>
  <div class="navLeft reactivePc">
    <div class="sec1 mb70">
      <img src="../../assets/img/logo.png"/>
    </div>
    <div class="lang dis">
      <span class="ch" :class="{changeStyle:changeSelectStyle=='cn'}"  @click="onlanguagechange('cn')">CH</span>
      <span class="seg"><b> / </b></span>
      <span class="en" :class="{changeStyle:changeSelectStyle=='en'}" @click="onlanguagechange('en')">EN</span>
    </div>
    <div class="node cursor-pointer" @click="JumpNode" id="nodeid">
      <img src="../../assets/img/jiedian.png" alt="">
      <div class="no">{{lang('node')}}</div>
    </div>
    <div class="min node cursor-pointer" @click="JumpNft">
      <img src="../../assets/img/wakuang.png" alt="">
    <div class="mining">{{lang('nftMining')}}</div>
    </div>
    <div class="my node cursor-pointer" @click="showOrHide">
      <img src="../../assets/img/wode.png" alt="" class="woImg">
      <div class="mine">{{lang('mine')}}</div>
      <img src="../../assets/img/jts.png" alt=""  id="up" v-if="show">
      <img src="../../assets/img/jtx.png" alt=""  id="down" v-if="!show">
    </div>
    <div class="myside" id="test">
      <div class="con1 cursor-pointer" @click="jumpMynode">{{lang('mynode')}}</div>
      <div class="con1 cursor-pointer" @click="jumpMyvote">{{lang('wodezhiya')}}</div>
      <div class="con1 cursor-pointer" @click="jumpMynft">{{lang('mynft')}}</div>
    </div>
    <div class="sec4">
      <!-- <div class="d3" v-if="isOpenType">
        <div class="d_height"></div>
      </div> -->

      <div class="d3" >
        <div class="d4">
          <a href="https://t.me/trustfi_fans" target="_blank">
          <!-- <img src="../../assets/img/link_github22x.png"/> -->
          <img src="../../assets/img/tl.png"/>
          </a>
        </div>
        <div class="d4">
          <a href="https://twitter.com/trustfiorg" target="_blank">
          <!-- <img src="../../assets/img/link_twitter22x.png"/> -->
          <img src="../../assets/img/tt.png"/>
          </a>
        </div>
        <div class="d4">
          <a href="https://medium.com/@trustfi" target="_blank">
          <!-- <img src="../../assets/img/link_telegram22x.png"/> -->
          <img src="../../assets/img/me.png"/>
          </a>
        </div>
        <div class="d4">
          <a href="https://github.com/TrustFiNetwork" target="_blank">
            <!-- <img src="../../assets/img/link_check2.png"/> -->
            <img src="../../assets/img/di.png"/>
          </a>
        </div>
        <div class="d4">
          <a href="https://www.certik.org/projects/trustfinetwork" target="_blank">
          <!-- <img src="../../assets/img/link_document2.png"/> -->
          <img src="../../assets/img/pro.png"/>
          </a>
        </div>
      </div>
      <!-- <div class="logoB">
        <img src="../../assets/img/logobot.png" alt="">
      </div> -->
    </div>
  
  
  </div>

</template>

<script>
  import { mixin1, mixin2, mixin3 } from "../../tools/mixins";
  import { lang, Toast } from "../../tools";
  import * as metaMaskTools from "../../tools/chain";
  import { busEvent } from '../busEvent'
  export default {
    name: 'navLeft',
    mixins: [mixin1, mixin2, mixin3],
    created() {
      this.option
    },
    data() {
      return {
        isOpenType: false,
        selectValue: 'cn',
        changeSelectStyle:'',
        setTime: null,
        setTimeOnly: null,
        value:'',
        show:false,
        getMyList:[],
        noAddr:'',
        flagShow:false,
        myInfoObj:0
      }
    },
    computed: {
    account() {
      return this.$store.state.accounts[0];
    },
  },
    mounted() {},
    beforeDestroy() {
      //清除定时器
      clearTimeout(this.setTime);
      clearTimeout(this.setTimeOnly);
    },
    methods: {
      initPage() {
          this.getNodeMyInfo()
      },
      initData() {
          this.getCountdown()
      },
      showOrHide(){
        this.show=!this.show
        var showState = document.getElementById("test").style.display;
          if (showState == "none") {
              document.getElementById("test").style.display = "block";
          } else {
              document.getElementById("test").style.display = "none";
          }
        },
      async getNodeMyInfo() {
        var res = await metaMaskTools.getNodeMyInfo(this.account);
        this.myInfoObj=res.depositAmount
      },
      jumpMynode() {
        if(this.myInfoObj==0){
          Toast(this.lang('meiyouchuangjianjiedian'))
        } else {
          this.$router.push({  name: 'mynode',params:{flagShowVal:false}});  
        }
      },
      jumpMyvote() {
        this.$router.push('/myVote');
      },
      jumpMynft() {
        this.$router.push('/myNft');
      },
      JumpNode(id) {   
        // var div = document.getElementById(id);
        // this.changeSelectStyle = id;
        // console.log(this.changeSelectStyle);
        // if(div.classList.contains('nodeAdd')) {
        //   div.classList.remove('nodeAdd')
        // } else {
        //   div.classList.add('nodeAdd')
        // }
        // div.style.backgroundColor=div.style.backgroundColor=='transparent'?
        // '#000007':'transparent';
          this.$router.push('/node');
        },
        JumpNft() {
          this.$router.push('/nftmining');
        },
        
        onRefresh(accounts) {
          this.initPage()
        },
        onlanguagechange(type) {
          this.changeSelectStyle = type;
          this.selectValue = type
          this.isOpenType = false
          this.$store.commit('setState', {
            languageType: this.selectValue
          })
        },
      lang(key) {
        return lang(key);
      }
    },
  }

</script>

<style lang="less">
.cursor-pointer{
  cursor: pointer;
}
  .navLeft {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 150px;
    padding: 40.5px 15px 0;
    background: #0F111F;
    text-align: center;
    z-index: 4000;
    .sec1 {
      > img {
        width: 50px;
        height: 37.5px;
      }
    }
    .changeStyle{
        font-size: 12px;
        color: #FFE000;
    }
    .lang {
      margin: 0 auto 90px;
      width: 50px;
      height: 15px;
      line-height: 16px;
      background:linear-gradient(rgba(#0077C5,.3) , rgba(#0045BB,.3));
      font-size: 12px;
      border-radius: 10px;
      color: #BAC2C5;
    }
    .node {
      display: flex;
      justify-content: space-between;
      width: 120px;
      height: 32.5px;   
      border-radius: 10px;
      padding: 8.5px 25px 8px 13.5px;
      color: #fff;
      font-size: 12px;
      margin-bottom: 20px;
      margin-top: 130px;
      .no {
        margin-top: 1.5px;
      }
    }
    .node:hover {
         background-color: #000007;
    }
    // .nodeAdd{
    //   background-color: #000007;
    // }
    .min {
      margin-top: 0;
      padding: 8.5px 20px 8px 13.5px;
      .mining {
        margin-top: 2px;
      }
    }
    .min:hover {
      background-color: #000007;
    }
    .my {
      margin-top: 0;
      margin-bottom: 2px;
      padding: 8.5px 25px 8px 13.5px;
      #up,#down {
        display: inline-block;
        width: 6.5px;
        height: 6.5px;
        margin-top: 5px;
      }
    }
    .myside {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align-last: left;
      color: #fff;
      font-size: 12px;
      padding-left: 35px;
      .con1 {
        padding: 0 5px 0 10px;
        height: 32.5px;
        line-height: 32.5px;
        border-radius: 5px;
      }
      .con1:hover {
         background-color: #000007;
      }
    }
    .sec4 {
      position: absolute;
      left: 0;
      bottom: -40px;
      width: 100%;
      padding: 50px 19.5px 70px 19.5px;

      .d1 {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        // margin-bottom: 30px;
      }

      .d2 {
        margin-right: 20px;
        cursor: pointer;

        img {
          width: 30px;
          height: 30px;
        }
      }

      .d3 {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
      .logoB {
        // margin-top: 30px;
        img {
          width: 75px;
          height: 20px;
        }
      }

      .d4 {
        cursor: pointer;

        img {
          width: 17.5px;
          height: 17.5px;
        }

        img:nth-of-type(1) {
          display: block;
        }

        img:nth-of-type(2) {
          display: none;
        }
      }
      // .d4:hover {
      //   img:nth-of-type(1) {
      //     display: none;
      //   }

      //   img:nth-of-type(2) {
      //     display: block;
      //   }
      // }
    }
  }
</style>

<style>
  .reactivePc .language_type {
    width: 160px;
    height: 40px;
    color: white;
    background-color: #25282d;
    border: #25282d;
    margin-bottom: 30px;
  }

  .reactivePc .language_type img {
    width: 25px;
    height: 25px;
    margin-top: 8px;
    margin-left: 8px;
  }

  .reactivePc .language_type em {
    float: right;
    margin-top: 11px;
    margin-right: 85px;
    font-size: 12px;
  }

  .reactivePc .language_type .em_english {
    margin-top: 12px !important;
    margin-right: 65px !important;
  }

  .reactivePc .language_type .e_select {
    float: right;
    width: 17px;
    height: 10px;
    margin-top: -23px;
    margin-right: 9px;
  }

  .reactivePc .language_type .li_01 {
    background-color: #25282d;
    height: 37px;
    margin-bottom: 30px;
    cursor: pointer;
  }

  .reactivePc .language_type .li_02 {
    margin-top: -25px;
    background-color: #25282d;
    cursor: pointer;
  }

  .reactivePc .language_type .li_03 {
    margin-top: -2px;
    background-color: #25282d;
    cursor: pointer;
  }

  .reactivePc .d3 .d_height {
    height: 37px;
  }
</style>
