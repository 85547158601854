<template>
  <div>
    <div class="reactivePc">
      <div class="navTopPc">
        <div class="sec1">
          <div class="d1 dis" @click="airdropShow">Claim</div>
          <div  class="d1" @click="showConnectWallet" v-if="$store.state.accounts.length === 0">
            <div class="d11" ></div>
            {{lang('a_lianjieqianbao')}}
          </div>
          <div v-if="$store.state.accounts.length > 0" class="d1" @click="showAccountInfo">
            {{cutAccount($store.state.accounts[0])}}
          </div>
         
        </div>
        <!-- <div class="my_parallel_assets">
          <van-dialog v-model="isParallelAssets" show-cancel-button :showConfirmButton="false" :showCancelButton="false" title="">
            <div class="wrap">
              <div class="sec1">
                <div class="d1">
                  <em :style="$store.state.languageType === 'cn' ? 'margin-left: -155px;' : 'margin-left: -110px;'">
                    {{lang('a_wodepingxingzichan')}}
                  </em>
                </div>
                <div style="opacity: .1" class="line"></div>

                <div class="d_assets">
                  <img src="../../assets/img/icon_pusdt.png" />
                  <div class="d_text">
                    <p>
                      <em>PUSD</em>
                      <em class="em_num">{{setDecimal(pusdtAssets, 6)}}</em>
                    </p>
                    <div class="d_contract">
                      {{$store.state.languageType === 'cn' ? '合约' : 'Contract'}}：{{pusdtText}}
                      <div @click="copyClick(pusdtContract)"
                           :style="$store.state.languageType === 'cn' ? 'margin-right: 130px;' : 'margin-right: 110px;'"
                           class="d_copy">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d_assets">
                  <img src="../../assets/img/icon_peth.png" />
                  <div class="d_text">
                    <p>
                      <em>PETH</em>
                      <em class="em_num">{{setDecimal(pethAssets, 6)}}</em>
                    </p>
                    <div class="d_contract">
                      {{$store.state.languageType === 'cn' ? '合约' : 'Contract'}}：{{pethText}}
                      <div @click="copyClick(pethContract)"
                           :style="$store.state.languageType === 'cn' ? 'margin-right: 130px;' : 'margin-right: 110px;'"
                           class="d_copy">
                      </div>
                    </div>
                  </div>
                </div>

                <img @click="closeDia" class="img1" src="../../assets/img/btn_close2x.png" />
              </div>
            </div>
          </van-dialog>
        </div> -->
        <div class="sec2 infoBtn">
            <img src="../../assets/img/logoTop.png" alt="">
        </div>
      </div>
    </div>
    <div class="reactiveMob">
      <div class="navTopMob">
        <div class="sec1">
          <div class="d1">
            <img src="../../assets/img/logo.png" />
          </div>
          
          <div class="d2">
            <div class="con" @click="airdropShow">Claim</div>
            <div class="d"  @click="toggleMobileMenuShow">
              <img v-if="!$store.state.mobileMenuShow" src="../../assets/img/icon_menu2x.png" />
              <img v-if="$store.state.mobileMenuShow" src="../../assets/img/icon_menu2x.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
  import { Toast,lang } from "@/tools";
  import {cutAccount} from '../../tools/index'
  import * as config from '../../config'
  import * as metaMaskTools from "../../tools/chain";
  import toolUtils from "../../tools/toolUtils";

  export default {
    name: 'navTopPc',
    data() {
      return {
        isParallelAssets: false,
        pusdtText: '',
        pusdtContract: '',
        pethText: '',
        pethContract: '',
        exchangeRateLanguage: ['pusdt', 'peth'],
        exchangeRate: {
          pusdt: 0,
          peth: 0,
        },
      }
    },
    methods: {
      airdropShow() {
        this.$store.commit("setState", {
          airdropShow: true,
        });
      },
      setDecimal(num, numDecimalMax){
        return toolUtils.setDecimal(num, numDecimalMax)
      },
      cutAccount(account){
        return cutAccount(account);
      },
      openBlockchainBrowser (index) {
        var contractAddress = null
        if (index === 0) {
          contractAddress = config.pusdtToken
        } else {
          contractAddress = config.pethToken
        }
        window.location.href = 'https://'+config.openUrlSLD+'etherscan.io/token/' + contractAddress
      },
      openParallelAssets () {
        this.isParallelAssets = true
        this.queryParallelAssets()
      },
      closeDia () {
        this.isParallelAssets = false
      },
      showConnectWallet(){
        this.$store.commit('setState', {
          connectWalletShow: true
        })
      },
      showAccountInfo(){
        this.$store.commit('setState', {
          accountInfoShow: !this.$store.state.accountInfoShow
        })
      },
      toggleMobileMenuShow(){
        this.$store.commit('setState', {
          mobileMenuShow: !this.$store.state.mobileMenuShow
        })
      },
       onLogout(accounts){
            if (!accounts) {
                //退出登录
                console.log('退出登录')
                Object.assign(this.$data, this.$options.data.call(this));
            } else {
                // console.log('登录成功')
                setTimeout(() => {
                    this.loopOnly()
                }, 1000)
            }
        },
      lang(key){
        return lang(key);
      }
    },
    mounted() {
    }
  }

</script>

<style lang="less">
  .navTopPc {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 230.5px;
    padding: 0 40px 0 220px;
    display: flex;
    flex-direction: column;
    background: url('../../assets/img/nav.png') no-repeat;
    z-index: 30;
    border-bottom: 0.5px solid #292828;
    >.sec1 {
      display: flex;
      justify-content: flex-end;
      padding-top: 20.5px;
      .d1:nth-of-type(1) {
        margin-right: 10px;
      }
      .d1{
        padding: 0 10px;
        height: 25px;
        background-color: #fff;
        border-radius: 14.5px;
        font-size: 10px;
        text-align: center;
        line-height: 25px;
        cursor: pointer;
        .d11 {
          width: 10.5px;
          height: 10.5px;   
          background: url('../../assets/img/qb.png') no-repeat;
          background-size: 100% 100%;
          display: inline-block;
          margin-right: 5px;
        }
      }

    }

    >.sec2{
      display: flex;
      align-items: center;
      justify-content: center;
        img {
          width: 123.5px;
          height: 33px;
          margin-top: 30px;
        }
      
      .d1:hover{
        // background: url("../../assets/img/btn_wallet22x.png") no-repeat left top;
        background-size: 100% 100%;
      }
      .d1_1{
        // background: url("../../assets/img/btn_wallet32x.png") no-repeat left top;
        background-size: 100% 100%;
      }
      .d1_1:hover{
        // background: url("../../assets/img/btn_wallet42x.png") no-repeat left top;
        background-size: 100% 100%;
      }
    }

    >.parallel_assets {
      margin-left: auto;
      .d_button {
        width: 120px;
        height: 40px;
        font-size: 12px;
        color: #4C525B;
        background: #24262a;
        border-radius: 20px;
        text-align: center;
        cursor: pointer;
        em {
          line-height: 40px;
        }
      }
    }

    >.my_parallel_assets .van-dialog {
      width: 380px;
      height: 250px;
    }

    >.my_parallel_assets .wrap {
      >.sec1 {
        height: 250px;
        background-color: #1b1d21;
        .line {
          width: 90%;
          background: #676767;
          height: 1px;
          margin-left: 5%;
        }
        .d1 {
          font-weight: 600;
          font-size: 16px;
          color: #fff;
          height: 60px;
          line-height: 60px;
          text-align: center;
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAgCAMAAAAVMLmlAAAAZlBMVEUAAAD/AAD/AADMADPVACrjHBznGBjlFRrkEhvkEhvjFRzjFhzlFRzkFB3kFB3jExzlFRzkFBvkFBzlFBzkFR3kFR3lFRzkFBzkFRzkFRzkFBzkFBzlFBzkFBzlFBzkFBzkFBzkFBzybyYjAAAAIXRSTlMAAgMFBgkVMTg5SVNjcnN4iJaZmqChrsDGx8jJ1Nfn+v1j0sEvAAAAgUlEQVQY03XRRxIDIQxE0T9yGuc0OCfd/5JeuCwaPNbuFRRILegps4LbTr1yF7dPFw/u7uKlu3j0cPXCo7oGjpl7GL9C1yHM8+EU2ITOAIfgGuASnAHcgpMeVperp/JHJyjaaPlpUkfYlQOmRsdPpuF89I0umQab7E/s9VLqlUW9AauqHnk8mEuiAAAAAElFTkSuQmCC) no-repeat 30px center;
          background-size: 7px 16px;
          margin-bottom: 5px;
          em {
            margin-left: -155px;
          }
        }
        .img1 {
          width: 11px;
          height: 12px;
          cursor: pointer;
          position: absolute;
          right: 25px;
          top: 20px;
        }
        .d_assets {
          padding: 10px;
          height: 60px;
          margin-top: 10px;
          .d_text {
            p {
              font-size: 18px;
            }
            color: #FFFFFF;
            margin-left: 55px;
            margin-top: -42px;
            .em_num {
              float: right;
              margin-right: 20px;
            }
            .d_contract {
              font-size: 12px;
              -webkit-transform-origin-x: 0;
              -webkit-transform: scale(0.90);
              .d_copy {
                height: 14px;
                width: 14px;
                float: right;
                margin-top: 3px;
                /*margin-right: 125px;*/
                cursor: pointer;
                background-image: url("../../assets/img/icon_copy.png");
              }
            }
          }
        }
      }
    }
  }
  .navTopMob{
    .sec1{
      width: 100%;
      height: 45px;
      // position: fixed;
      // left: 0;
      z-index: 999;
      background: #17191C;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding:  0 14px;
      .d1{
        img{
          height: 30px;
          margin-top: 5px;
        }
      }
      .d2{
        display: flex;
        img{
          width: 22px;
          height: 22px;
          margin-top: 5px;

        }
      }
      .con {
        width: 60px;
        height: 22px;
        line-height: 22px;
        background-color: #fff;
        color: #000;
        font-size: 12px;
        border-radius: 11px;
        text-align: center;
        margin:5px 10px;
      }
    }
  }
</style>
