import { CellGroup } from "vant";
import store from "../store";
var mixin1 = {
  methods: {
    onAccountsChanged: function () {
      var _this = this;
      window.ethereum.on("accountsChanged", function (accounts) {
        _this.onRefresh(accounts);
      });
    },
  },
};
//当连接上钱包，然后调用方法，轮询调用
var mixin2 = {
  methods: {
    loop() {
      let is_refresh = false;
      this.setTimeOnly = setInterval(() => {
        const { accounts } = this.$store.state;
        if (!accounts.length) {
          is_refresh = false;
        }
        if (accounts.length > 0 && !is_refresh) {
          // is_refresh = true;
          this.initData && this.initData();
        }
      }, 1000);
    },
  },
  beforeDestroy() {
    clearTimeout(this.setTimeOnly);
  },
  created() {
    var _this = this;
    window.ethereum.on("accountsChanged", function (accounts) {
      store.commit("setState", {
        accounts,
      });
      _this.onRefresh && _this.onRefresh(accounts);
    });
    this.loop();
  },
};
//当连接上钱包，然后调用方法，只调用一次
var mixin3 = {
  data() {
    return {
      is_refresh: false
    }
  },
  methods: {
    loop() {
      this.is_refresh = false;
      this.setTimeOnly = setInterval(() => {
        const { accounts } = this.$store.state;
     
        if (!accounts.length) {
          this.is_refresh = false;
        }
        if (accounts.length > 0 && !this.is_refresh) {
          this.is_refresh = true;
         
          this.initPage && this.initPage();
          clearTimeout(this.setTimeOnly);
        }
      }, 1000);
    },
  },
  beforeDestroy() {
    this.is_refresh = false
    clearTimeout(this.setTimeOnly);
  },
  created() {
    this.is_refresh = false
    var _this = this;
    window.ethereum.on("accountsChanged", function (accounts) {
      store.commit("setState", {
        accounts,
      });
      _this.onRefresh && _this.onRefresh(accounts);
    });
   
    this.loop();
  },
};
export { mixin1, mixin2, mixin3 };
