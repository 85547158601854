<template>
  <!--授权弹窗-->
  <div class="Author">
    <van-dialog v-model="$store.state.authorShow" show-cancel-button :showConfirmButton="false"
                :showCancelButton="false" title="" >
      <div class="wrap">
        <div class="sec1">
          <div class="d1" v-if="$store.state.toastShowType === 0">
          </div>
          <div class="d3" v-if="$store.state.toastShowType === 2">
          </div>
          <div class="d2" v-if="$store.state.toastShowType === 0">{{lang('querenzhong')}}</div>
          <div class="d2" v-if="$store.state.toastShowType === 1">{{lang('guangbojiaoyizhong')}}</div>
          <div class="d2" v-if="$store.state.toastShowType === 2">{{lang('jiaoyichenggong')}}</div>
          <img @click="hide" class="img1" src="../../assets/img/btn_close2x.png"/>
          <div class="d8">
            <button  class="active" v-if="$store.state.toastShowType === 0" @click="hide">{{lang('a_haode')}}</button>
            <button  class="active" v-if="$store.state.toastShowType === 2" @click="hide">{{lang('a_chakanjiaoyi')}}</button>
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
  import {
    lang,
    Toast,
  } from "../../tools";
  import {getNetValue, redemptionIns, subscribeIns} from "../../tools/chain";
  import * as metaMaskTools from "../../tools/chain";
  import * as config from "../../config";
  import toolUtils from "../../tools/toolUtils";

  export default {
    name: 'Author',
    data(){
      return {
        depositVal: '',
        tabIndex: 0,
        // tokenList: config.tokenList,
      }
    },
    created() {
    },
    mounted() {
  },
    beforeDestroy() {
  },
    computed: {
    account() {
      return this.$store.state.accounts[0];
    },
  },
    methods: {
      lang(key){
        return lang(key);
      },
    //   loopOnly() {
    //   if (this.$store.state.accounts.length > 0) {
    //     clearTimeout(this.setTimeOnly);
    //     // this.getPleRede()
    //     return;
    //   }
    //   clearTimeout(this.setTimeOnly);
    //   this.setTimeOnly = setTimeout(() => {
    //     this.loopOnly();
    //   }, 500);
    // },
      getNetValue() {
        if (getNetValue() <= 0) {
          return 1;
        }
        return getNetValue();
      },
      hide() {
        this.$store.commit('setState', {
          authorShow: false
        })
      },
    //   async getPleRede() {
    //     console.(111111);
    //   var res = await metaMaskTools.getPleRede(this.account);
    //   console.log(res);
    //   Toast('赎回成功')
    // },

    },
  }

</script>

<style lang="less">
  .Author {
    letter-spacing: 3px;

    .van-dialog {
      width: 310px;
    }

    .wrap {
      > .sec1 {
        background: #152E55;
        border-radius: 4px;
        padding: 0 30px 30px;
        position: relative;
        height: auto;
        overflow: hidden;
        border: 0;
        > .img1 {
          width: 12px;
          height: 12px;
          cursor: pointer;
          position: absolute;
          right: 25px;
          top: 20px;
        }

        .d1 {
          width: 63px;
          height: 63px;
          background: url('../../assets/img/confirm.png') no-repeat;
          background-size: 100% 100%;
          text-align: center;
          margin: 120px auto 0;
          animation: animal 1s infinite linear;
        }
        .d3 {
          width: 63px;
          height: 63px;
          background: url('../../assets/img/succ.png') no-repeat;
          background-size: 100% 100%;
          text-align: center;
          margin: 120px auto 0;
        }
        .d2 {
            font-size: 12px;
            color: #fff;
            margin: 20px 0 60px;
            text-align: center;
        }
        .d8 {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 30px;
          // button {
            
          // }

          button.active {
            width: 100px;
            height: 25px;
            border: 0;
            text-align: center;
            line-height: 25px;
            font-size: 12px;
            color: #fff;
            background: linear-gradient(#0077c5, #0045bb);
            border-radius: 10px;
            margin: 0 18px;
          }

          // button.active1 {
          //   background: linear-gradient(#0077c5, #0045bb);
          //   border: 0;
          //   color: #fff;
          //   border-radius: 10px;
          // }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .Vote {
      .van-dialog {
        width: 300px;
      }

      .wrap {
        > .sec1 {
          padding: 0 20px 15px;

          .img1 {
            right: 21px;
            top: 16px;
          }

          .d1 {
            padding: 0 0 0 20px;
            height: 45px;
            line-height: 45px;
            font-size: 13px;
            margin-bottom: 10px;
          }
          .d6,.d7,.d8,.d9,.d10{
            font-size: 10px;
          }
          .d8{
            button{
              height: 40px;
              line-height: 40px;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
  .line_tc {
    margin-bottom: 20px;
    background: #fff;
    height: 1px;
  }
</style>
