<template>
  <!--质押弹窗-->
  <div class="Redeem">
    <van-dialog v-model="$store.state.redeemShow" show-cancel-button :showConfirmButton="false"
                :showCancelButton="false" title="">
      <div class="wrap">
        <div class="sec1">
          <div class="d1">
            {{lang('xnftduihuannft')}}
          </div>
          <img @click="hide" class="img1" src="../../assets/img/btn_close2x.png"/>
          <div class="con">
              <div class="ti">{{lang('nftleixing')}}</div>
              <div class="btn">
        <van-tabs v-model="active">
        <van-tab title="Benefit" name="a">
            <div class="d2 mb15">
            <div class="d3">{{lang('duihuannftshulaing')}}</div>
            <div class="d3">
              {{lang('a_keyong')}}xTFI：
              <div class="d4">
                {{BigNumberStr(exchangeNum,18,3)}}
              </div>
            </div>
          </div>
          <div class="d5">
            <input v-model="BenefitVal" placeholder="" />
            <div class="d6" @click="BenefitValMax">{{lang('a_zuida')}}</div>
          </div>
          <div class="pro">
            <div class="proL">{{lang('duihuanbili')}}</div>
            <div class="proR">1 Benefit NFT=100xTFI</div>
          </div>
          <div class="line"></div>
          <div class="d8">
            <button  class="active .cursor-pointer" @click="submit(2,BenefitVal)">{{lang('a_queren')}}</button>
            <!-- <button class="active1" >{{lang('a_queren')}}</button> -->
          </div>
      </van-tab>
      <van-tab title="Honor" name="b">
           <div class="d2 mb15">
            <div class="d3">{{lang('duihuannftshulaing')}}</div>
            <div class="d3">
              {{lang('a_keyong')}}xTFI：
              <div class="d4">
                {{BigNumberStr(exchangeNum,18,3)}}
              </div>
            </div>
          </div>
          <div class="d5">
            <input v-model="HonorVal" placeholder="" />
            <div class="d6" @click="HonorValMax">{{lang('a_zuida')}}</div>
          </div>
          <div class="pro">
            <div class="proL">{{lang('duihuanbili')}}</div>
            <div class="proR">1 Honor NFT=2100xTFI</div>
          </div>
          <div class="line"></div>
          <div class="d8">
            <!-- <button @click="submit" class="active" v-if="depositVal*1 <= $store.state.accountInfo.amount[$store.state.insuranceTabIndex]*1">{{lang('a_queren')}}</button> -->
            <button class="active1" @click="submit(1,HonorVal)">{{lang('a_queren')}}</button>
          </div>
          </van-tab>
          </van-tabs>
        </div>
          </div>
          
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
  import {lang,Toast,setUnit,} from "../../tools";
  import * as config from "../../config";
  import { mixin1, mixin2, mixin3 } from "../../tools/mixins";
  import * as metaMaskTools from "../../tools/chain";
  import { busEvent } from '../busEvent.js'


  export default {
    name: 'Redeem',
    mixins: [mixin1, mixin2, mixin3],
    data(){
      return {
        active: 'a',
        depositVal: '',
        tabIndex: 0,
        exchangeNum:0,
        HonorVal:'',
        BenefitVal:'',
        interval: null,

      }
    },
    computed: {
      account() {
        return this.$store.state.accounts[0];
      },
    },
    beforeDestroy() {
    clearInterval(this.interval);
    },
    mounted() {
      this.interval = setInterval(() => {
      this.getExchangeXnFT();
    }, 5000);
    },
    methods: {
    initPage() {
      this.getExchangeXnFT()
    },
    initData() {

    },
    onRefresh(accounts) {
      this.initPage();
    },
    async getExchangeXnFT() {
        var res = await metaMaskTools.getExchangeXnFT(this.account);
        this.exchangeNum=res
    },
    lang(key){
        return lang(key);
      },
    BenefitValMax() {
        this.BenefitVal=this.BigNumberStr(this.exchangeNum,18,3)
        this.BenefitVal=this.BigNumberStr(this.BenefitVal/100,0,0)
        if(this.BenefitVal>10) {
          this.BenefitVal=10
        } else {
          this.BenefitVal=this.BenefitVal
        }
    },
    HonorValMax() {
      this.HonorVal=this.BigNumberStr(this.exchangeNum,18,3)
      this.HonorVal=this.BigNumberStr(this.HonorVal/2100,0,0)
      console.log(this.HonorVal,'this.HonorVal');
        if(this.HonorVal>10) {
          this.HonorVal=10
        } else {
          this.HonorVal=this.HonorVal
        }
    },
    hide() {
        this.$store.commit('setState', {
          redeemShow: false
        })
      },
    async submit(type,amount){
      var re = /^[1-9]+[0-9]*]*$/; 
      if(!re.test(amount)) {
           Toast(this.lang('bukeshuruxiaoshu'))
           return
        } else {
          if(this.BenefitVal>this.BigNumberStr(this.exchangeNum,18)/100 ) {
            console.log('B1');
            Toast(this.lang('zhanghuyuebuzu'))
            this.BenefitVal=''
            return
          } 
          if(this.HonorVal>this.BigNumberStr(this.exchangeNum,18)/2100 ) {
            console.log('H1');
            Toast(this.lang('zhanghuyuebuzu'))
            this.HonorVal=''
            return
          } 
          if(this.HonorVal/1>10 || this.BenefitVal/1>10) {
            Toast(this.lang('zuiduokeduihuan'))
            return
          }
          var res=await metaMaskTools.getExchange(this.account,type,amount);
            Toast(this.lang('duihuanchenggong'))
            this.HonorVal='',
            this.BenefitVal=''
            this.hide()
            busEvent.$emit('getBusRedeem',true)
        }
        
    },
    },
  }

</script>

<style lang="less">
.cursor-pointer{
  cursor: pointer;
}
  .Redeem {
    letter-spacing: 3px;

    .van-dialog {
      width: 360px;
    }

    .wrap {
      > .sec1 {
        background: #152E55;
        border-radius: 4px;
        padding: 0 30px 30px;
        position: relative;
        height: auto;
        overflow: hidden;

        > .img1 {
          width: 12px;
          height: 12px;
          cursor: pointer;
          position: absolute;
          right: 25px;
          top: 20px;
        }

        .d1 {
          font-weight: 600;
          font-size: 14px;
          color: #fff;
          height: 70px;
          line-height: 70px;
          text-align: center;
          margin-top: 20px;
        }
        .con {
            .ti {
                font-size: 12px;
                color: #fff;
                margin-bottom: 15px;
            }
            /deep/ .van-tabs {
                background:rgba(#000,.4);
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                color: #fff;
            }
            /deep/ .van-tabs__wrap {
                background:rgba(#000,.4);
                width: 100%;
                height: 44px;
                line-height: 44px;
                padding: 3px; 
            }
            .van-tab {
                font-size: 12px;
                height: 40px;
                line-height: 40px;
                border-radius: 10px;
                color: #fff;
                
            }
            .van-tab--active {
                background: linear-gradient( #0077C5 , #0045BB);
                border: none;
                color: #fff;
                height: 40px;
            }
            .van-tabs__nav {
              border-radius: 10px;
              background:rgba(#000,.4);
              padding: 2px;
            }
            .van-tabs__nav--line {
                padding-bottom: 0;
            }
            .van-tabs__line {
                display: none;
            }
        }
        .d2 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 30px;
        }

        .d3 {
          font-size: 12px;
          color: #ccc;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }

        .d4 {
          color: #fff;
        }
        .d5 {
          position: relative;
          margin-bottom: 10px;
          input {
            width: 100%;
            height: 46px;
            line-height: 46px;
            font-size: 24px;
            color: #fff;
            padding: 0 20px;
            border: solid 1px #0F111F;
            background: #0F111F;
            border-radius: 10px;
          }
        }
        .pro {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          color: #fff;
          margin-top: 20px;
        }
        .line {
          height: 1px;
          color: #ccc;
          opacity: .3;
          margin-top: 20px;
        }
        .d6 {
          color: #fff;
          font-size: 12px;
          background: linear-gradient(#0077c5, #0045bb);
          text-align: center;
          padding: 3px 6px;
          position: absolute;
          right: 15px;
          top: 12px;
          border-radius: 8px;
          cursor: pointer;
        }

        .d7 {
          font-size: 14px;
          color: #fff;
          position: absolute;
          right: 20px;
          top: 21px;
        }

        .d8 {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 30px;
          button {
            width: 120px;
            height: 50px;
            border: 0;
            text-align: center;
            line-height: 50px;
            font-size: 14px;
            color: #fff;
            background: linear-gradient(#0077c5, #0045bb);
            border-radius: 10px;
            margin: 0 18px;
          }

          button.active {
            background: linear-gradient(#0077c5, #0045bb);
            border: 0;
            border-radius: 10px;
          }

          button.active1 {
            background: linear-gradient(#0077c5, #0045bb);
            border: 0;
            color: #fff;
            border-radius: 10px;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .Redeem {
      .van-dialog {
        width: 300px;
      }

      .wrap {
        > .sec1 {
          padding: 0 20px 15px;

          .img1 {
            right: 21px;
            top: 16px;
          }

          .d1 {
            padding: 0 0 0 20px;
            height: 45px;
            line-height: 45px;
            font-size: 13px;
            margin-bottom: 10px;
          }

          .d2{
            margin-bottom: 10px;
          }

          .d2_1{
            margin-bottom: 25px;
          }

          .d5 {
            position: relative;
            background: #17191C;
            margin-bottom: 10px;

            input {
              height: 40px;
              line-height: 40px;
              padding: 0 10px;
            }
          }
          .d6 {
            top: 8px;
          }
          .d7 {
            top: 11px;
          }
          .d6,.d7,.d8,.d9,.d10{
            font-size: 10px;
          }
          .d8{
            button{
              height: 40px;
              line-height: 40px;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
  .line_tc {
    margin-bottom: 20px;
    background: #fff;
    height: 1px;
  }
</style>
