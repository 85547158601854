/**
 * 设置小数
 * @returns {String}
 */
export function setDecimal (numVal, numDecimalMax) {
    if (numVal) {
        // 小数型
        if (numVal.toString().indexOf('.') > -1) {
            var num
            if (numVal.toString().substring(numVal.toString().indexOf('.') + 1).length > numDecimalMax) {
                num = parseFloat(numVal.toString().substring(0, (numDecimalMax + numVal.toString().indexOf('.') + 1)))
                return num.toString()
            } else {
                num = parseFloat(numVal)
                return num.toString()
            }
            // 整数型
        } else {
            if (parseFloat(numVal) === 0) {
                return '0'
            } else {
                return numVal.toString()
            }
        }
    } else {
        return '0.0'
    }
}

/**
 * 是否PC端
 * @returns {boolean}
 */
const isPC = function () {
    var userAgentInfo = navigator.userAgent
    var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
    var isPc = true
    for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
            isPc = false
            break
        }
    }
    // true为PC端，false为手机端
    return isPc
}

const toolUtils = {
    setDecimal,
    isPC
}

export default toolUtils
