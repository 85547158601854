<template>
  <!--投票弹窗-->
  <div class="VoteRede">
    <van-dialog v-model="$store.state.voteRedeShow" show-cancel-button :showConfirmButton="false"
                :showCancelButton="false" title="">
      <div class="wrapRede">
        <div class="sec1">
          <div class="d1">
            {{lang('a_shuhui')}}
          </div>
          <img @click="hide" class="img1" src="../../assets/img/btn_close2x.png"/>
          <div class="d2 mb15">
            <!-- <div class="d3">{{lang('shuhuishuliang')}}</div> -->
             <div class="d3">
              {{ lang("a_keyong") }}：
              <div class="d4">{{BigNumberStr(snftNum,0,3) }}STFI</div>
            </div>
          </div>
          <div class="d5">
            <input v-model="voteAmount" placeholder="" />
            <div class="d6" @click="max">{{lang('a_zuida')}}</div>
          </div>
          
          <div class="d8">
            <!-- v-if="voteAmount*1 <= nftNum/1" -->
             <button class="active  .cursor-pointer" v-if="getAllowanceNum <= 0"  @click="getApprove">{{lang('a_shouquan')}}</button>
            <button  class="active .cursor-pointer" v-if="getAllowanceNum >0"  @click="submit">{{lang('queding')}}</button>
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
  import {
    lang,
    Toast,
  } from "../../tools";
  import * as metaMaskTools from "../../tools/chain";
  import {getNetValue, redemptionIns, subscribeIns} from "../../tools/chain";
  import * as config from "../../config";
  import toolUtils from "../../tools/toolUtils";
  import { mixin1, mixin2, mixin3 } from "../../tools/mixins";
  import { busEvent } from '../busEvent.js'

  export default {
    name: 'VoteRede',
    mounted() {
    },
    created() {
    },
    mixins: [mixin1, mixin2, mixin3],
    computed: {
    account() {
      return this.$store.state.accounts[0];
    },
    selectVoteRede() {
      return this.$store.state.selectVoteRede;
    },
    selectVoteRedeNum() {
      return this.BigNumberStr(this.$store.state.selectVoteRedeNum,18);
    }
  },
    data(){
      return {
        snftNum:0,
        voteAmount: '',
        getAllowanceNum:0,
        tabIndex: 0,
        nftNum:0,
        myInfo:0,
        nodeaddress:'',
        // tokenList: config.tokenList,
      }
    },
    methods: {
    initPage() {
      this.getAllowance()
      this.getMyVoteInfo()
      this.getbalanceOfSNFT()
    },
    initData() {
    },
     onRefresh(accounts) {
        this.initPage()
    },
      lang(key){
        return lang(key);
      },
    async getMyVoteInfo() {
      var res = await metaMaskTools.getMyVoteInfo(this.account);
      this.myInfo=this.BigNumberStr(res.totalAmount,18,0)
    },
    max() {
      this.voteAmount=this.selectVoteRedeNum
    },
      getNetValue() {
        if (getNetValue() <= 0) {
          return 1;
        }
        return getNetValue();
      },
      hide() {
        this.$store.commit('setState', {
          voteRedeShow: false
         
        })
         this.voteAmount=''
      },
      async getbalanceOfSNFT() {
      var res = await metaMaskTools.getbalanceOfSNFT(this.account);
      this.snftNum = this.BigNumberStr(res, 18);
    },
       async getAllowance() {
          var res=await metaMaskTools.getRedeAllowance(this.account);
          this.getAllowanceNum=res
        },
        async getApprove() {
          await metaMaskTools.getRedeApprove(this.account);
          Toast(this.lang('a_shouquanchenggong'))
          this.getAllowance()
        },
       async getbalanceOfNFT() {
          var res = await metaMaskTools.getbalanceOfNFT(this.account);
          this.nftNum=this.BigNumberStr(res, 18,3);
        }, 
      async submit(){
      if(this.BigNumberStr(this.snftNum, 0,3)<this.voteAmount/1 ) {
        Toast(this.lang('shuhuishulaingbuke'))
        return
      } else {
        if(this.selectVoteRedeNum/1<this.voteAmount/1) {
          Toast(this.lang('shuhuishulaingbuzu'))
          return
        } else {
           await metaMaskTools.getVoteRede(this.selectVoteRede,this.voteAmount);
          Toast(this.lang('shuhuichenggong'))
          this.getMyVoteInfo()
          this.voteAmount=''
          this.hide();
          busEvent.$emit('getBusRede',true)
        }
       
      }
        
          
        
      },
    },
  }

</script>

<style lang="less">
.cursor-pointer{
  cursor: pointer;
}
  .VoteRede {
    letter-spacing: 3px;

    .van-dialog {
      width: 360px;
    }

    .wrapRede {
      > .sec1 {
        background: #152E55;
        border-radius: 4px;
        padding: 0 30px 30px;
        position: relative;
        height: auto;
        overflow: hidden;
        border: 0;
        > .img1 {
          width: 12px;
          height: 12px;
          cursor: pointer;
          position: absolute;
          right: 25px;
          top: 20px;
        }

        .d1 {
          font-weight: 600;
          font-size: 14px;
          color: #fff;
          text-align: center;
          margin:15px 0 45px;
        }

        .d2 {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }

        .d3 {
          font-size: 12px;
          color: #ccc;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }

        .d4 {
          color: #fff;
        }
        .d5 {
          position: relative;
          margin-bottom: 10px;
          input {
            width: 100%;
            height: 50px;
            line-height: 50px;
            font-size: 24px;
            color: #fff;
            padding: 0 20px;
            border: solid 1px #0F111F;
            background: #0F111F;
            border-radius: 10px;
          }
        }

        .d6 {
          color: #fff;
          font-size: 12px;
          background: linear-gradient(#0077c5, #0045bb);
          text-align: center;
          padding: 4px 12px;
          position: absolute;
          right: 10px;
          top: 13px;
          border-radius: 8px;
          cursor: pointer;
        }

        .d7 {
          font-size: 14px;
          color: #fff;
          position: absolute;
          right: 20px;
          top: 21px;
        }

        .d8 {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 30px;
          // button {
            
          // }

          button.active {
            width: 100px;
            height: 25px;
            border: 0;
            text-align: center;
            line-height: 25px;
            font-size: 12px;
            color: #fff;
            background: linear-gradient(#0077c5, #0045bb);
            border-radius: 10px;
            margin: 0 18px;
          }

          // button.active1 {
          //   background: linear-gradient(#0077c5, #0045bb);
          //   border: 0;
          //   color: #fff;
          //   border-radius: 10px;
          // }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .Vote {
      .van-dialog {
        width: 300px;
      }

      .wrapRede {
        > .sec1 {
          padding: 0 20px 15px;

          .img1 {
            right: 21px;
            top: 16px;
          }

          .d1 {
            padding: 0 0 0 20px;
            height: 45px;
            line-height: 45px;
            font-size: 13px;
            margin:30px 0 20px;
          }

          .d2{
            margin: 20px 0 10px;
          }

          .d2_1{
            margin-bottom: 25px;
          }

          .d5 {
            position: relative;
            background: #17191C;
            margin-bottom: 10px;
            border-radius: 10px;
            input {
              height: 40px;
              line-height: 40px;
              padding: 0 10px;
              
            }
          }
          .d6 {
            top: 8px;
          }
          .d7 {
            top: 11px;
          }
          .d6,.d7,.d8,.d9,.d10{
            font-size: 10px;
          }
          .d8{
            button{
              height: 40px;
              line-height: 40px;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
  .line_tc {
    margin-bottom: 20px;
    background: #fff;
    height: 1px;
  }
</style>
