<template>
  <!--投票弹窗-->
  <div class="Vote">
    <van-dialog
      v-model="$store.state.airdropShow"
      show-cancel-button
      :showConfirmButton="false"
      :showCancelButton="false"
      title=""
    >
      <div class="wrap">
        <div class="sec1">
          <div class="d1">Claim</div>
          <img
            @click="hide"
            class="img1"
            src="../../assets/img/btn_close2x.png"
          />
          <div class="d2 mb15">
            <div class="d3">
              {{lang('dangqiankelignqu')}}(TFI)
            </div>
          </div>
          <div class="d5">
            <div class="input">{{BigNumberStr(availableNum,18)}}</div>

            <div class="d6"  @click="submit">
              Claim
            </div>
          </div>
          <div class="line"></div>
          <div class="con">
              <div class="con1">
                  <span>{{lang('yilingqu')}}</span>
                  <span>{{BigNumberStr(receivedNum,18,1)}} TFI</span>
              </div>
              <div class="con1" v-if="seedNum>0">
                  <span>{{lang('zhongzilun')}}</span>
                  <span>{{BigNumberStr(seedNum,18,1)}} TFI</span>
              </div>
              <div class="con1" v-if="placementNum>0">
                  <span>{{lang('simulun')}}</span>
                  <span>{{BigNumberStr(placementNum,18,1)}} TFI</span>
              </div>
          </div>
          <div class="d8">
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {lang,Toast,} from "../../tools";
import * as metaMaskTools from "../../tools/chain";
import * as config from "../../config";
import { mixin1, mixin2, mixin3 } from "../../tools/mixins";
import { busEvent } from '../busEvent.js'

export default {
  name: "Airdrop",
  mounted() {},
  mixins: [mixin1, mixin2, mixin3],
  created() {
  },
  data() {
    return {
      pendingobj:{
        roundOneWaitRelease:0,
        roundTwoWaitRelease:0
      },
      pendingTotal:{
        roundOneRelease:0,
        roundTwoRelease:0,
        roundOneTotal:0,
        roundTwoTotal:0
      },
      inftNum:0,
      getAllowanceNum:0
      
    };
  },
  computed: {
    account() {
      return this.$store.state.accounts[0];
    },
    seedNum() {
      return this.pendingTotal.roundOneTotal/1 -this.pendingTotal.roundOneRelease/1-this.pendingobj.roundOneWaitRelease;
    },
    placementNum() {
      return this.pendingTotal.roundTwoTotal/1-this.pendingTotal.roundTwoRelease/1-this.pendingobj.roundTwoWaitRelease;
    },
    availableNum() {
      return this.pendingobj.roundOneWaitRelease/1+this.pendingobj.roundTwoWaitRelease/1;
    },
    receivedNum() {
      return this.pendingTotal.roundOneRelease/1+this.pendingTotal.roundTwoRelease/1;
    }
  },
  methods: {
    initPage() {
      this.getpendingInft()
      this.getpendingTotal()
      this.getbalanceOfNFT()
    },
    initData() {},
    onRefresh(accounts) {
      this.initPage();
    },
    lang(key) {
      return lang(key);
    },
    hide() {
      this.$store.commit("setState", {
        airdropShow: false,
      });
    },
    // async getAllowance() {
    //   var res = await metaMaskTools.getNodeAllowance(this.account);
    //   this.getAllowanceNum = res;
    // },
    // async getNodeApprove() {
    //   await metaMaskTools.getNodeApprove(this.account);
    //   Toast(this.lang("a_shouquanchenggong"));
    //   this.getAllowance();
    // },
    async getbalanceOfNFT() {
      var res = await metaMaskTools.getbalanceOfNFT(this.account);
      this.inftNum = this.BigNumberStr(res, 18);
    },
    async getpendingInft() {
       var res=await metaMaskTools.getpendingInft(this.account);
       this.pendingobj=res
    },
    async getpendingTotal() {
       var res=await metaMaskTools.getpendingTotal(this.account);
       this.pendingTotal=res
    },
    async submit() {
      var res=await metaMaskTools.getClaim(this.account);
      Toast(this.lang('lingquchenggong'))
      this.getpendingInft()
      this.getpendingTotal()
      this.hide()
    },
  },
};
</script>

<style lang="less">
.cursor-pointer{
  cursor: pointer;
}
.Vote {
  letter-spacing: 3px;

  .van-dialog {
    width: 360px;
  }

  .wrap {
    > .sec1 {
      background: #152e55;
      border-radius: 4px;
      padding: 0 30px 5px;
      position: relative;
      height: auto;
      overflow: hidden;
      border: 0;
      > .img1 {
        width: 12px;
        height: 12px;
        cursor: pointer;
        position: absolute;
        right: 25px;
        top: 20px;
      }

      .d1 {
        font-weight: 600;
        font-size: 14px;
        color: #fff;
        height: 70px;
        line-height: 70px;
        text-align: center;
        margin-top: 0;
      }

      .d2 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .con {
          color: #fff;
          font-size: 12px;
          .con1 {
              display: flex;
              justify-content: space-between;
              margin-bottom: 10px;
          }
      }
      .d3 {
        font-size: 12px;
        color: #ccc;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      .d4 {
        color: #fff;
      }
      .d5 {
        display: flex;
        justify-content: space-between;
        position: relative;
        // margin-bottom: 10px;
        .input {
          width: 100%;
          height: 50px;
          line-height: 50px;
          font-size: 24px;
          color: #fff;
          // margin-bottom: 10px;
        }
      }
      .line {
        background-color: rgba(#fff,.3);
        margin-bottom: 15px;
      }
      .d6 {
        color: #fff;
        font-size: 12px;
        background: linear-gradient(#0077c5, #0045bb);
        text-align: center;
        padding: 4px 12px;
        position: absolute;
        top: 13px;
        right: 0;
        border-radius: 8px;
        cursor: pointer;
      }

      .d7 {
        font-size: 14px;
        color: #fff;
        position: absolute;
        right: 20px;
        top: 21px;
      }

      .d8 {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        // button {

        // }

        button.active {
          width: 100px;
          height: 25px;
          border: 0;
          text-align: center;
          line-height: 25px;
          font-size: 12px;
          color: #fff;
          background: linear-gradient(#0077c5, #0045bb);
          border-radius: 10px;
          margin: 0 18px;
        }

        // button.active1 {
        //   background: linear-gradient(#0077c5, #0045bb);
        //   border: 0;
        //   color: #fff;
        //   border-radius: 10px;
        // }
      }
    }
  }
}

@media (max-width: 768px) {
  .Vote {
    .van-dialog {
      width: 300px;
    }

    .wrap {
      > .sec1 {
        padding: 0 20px 15px;

        .img1 {
          right: 21px;
          top: 16px;
        }

        .d1 {
          padding: 0 0 0 20px;
          height: 45px;
          line-height: 45px;
          font-size: 13px;
          margin: 30px 0 20px;
        }

        .d2 {
          margin: 20px 0 10px;
        }

        .d2_1 {
          margin-bottom: 25px;
        }

        .d5 {
          background: #17191c;
          margin-bottom: 10px;
          border-radius: 10px;
          .input {
            height: 40px;
            line-height: 40px;
            // padding: 0 10px;
          }
        }
        .d6 {
          top: 8px;
        }
        .d7 {
          top: 11px;
        }
        .d6,
        .d7,
        .d8,
        .d9,
        .d10 {
          font-size: 10px;
        }
        .d8 {
          button {
            height: 40px;
            line-height: 40px;
            font-size: 13px;
          }
        }
      }
    }
  }
}
.line_tc {
  margin-bottom: 20px;
  background: #fff;
  height: 1px;
}
</style>
