<template>
  <div class="navIndex">
    <Toast />
    <ConnectWallet />
    <Vote/>
    <Pledge/>
    <Redeem/>
    <Author/>
    <VoteRede/>
    <MyNodeVote/>
    <PledgeRede/>
    <Airdrop/>
  </div>
</template>

<script>
  import Toast from './Toast'
  import ConnectWallet from './ConnectWallet'
  import Vote from './Vote'
  import Pledge from './Pledge'
  import Redeem from './Redeem'
  import Author from './Author'
  import VoteRede from './VoteRede'
  import MyNodeVote from './MyNodeVote'
  import PledgeRede from './PledgeRede'
  import Airdrop from './Airdrop'


  export default {
    components: { Toast, ConnectWallet,Vote,Pledge,Redeem,Author,VoteRede,MyNodeVote,PledgeRede,Airdrop},
  }

</script>

<style lang="less">
  
</style>
