<template>
  <div class="reactiveMob MobileMenu" v-show="$store.state.mobileMenuShow">
    <div class="sec1 mb70">
      <img src="../../assets/img/logo.png"/>
    </div>
    <div class="lang dis">
      <span class="ch" :class="{changeStyle:changeSelectStyle=='cn'}" @click="onlanguagechange('cn')">CH</span>
      <span class="seg"><b> / </b></span>
      <span class="en" :class="{changeStyle:changeSelectStyle=='en'}" @click="onlanguagechange('en')">EN</span>
    </div>
    <div class="node" @click="JumpNode">
      <img src="../../assets/img/jiedian.png" alt="">
      <div class="no">{{lang('node')}}</div>
    </div>
    <div class="min node" @click="JumpNft">
      <img src="../../assets/img/wakuang.png" alt="">
      <div class="mining">{{lang('nftMining')}}</div>
    </div>
    <div class="my node" @click="showOrHide">
      <img src="../../assets/img/wode.png" alt="" class="woImg">
      <div class="mine">{{lang('mine')}}</div>
      <img src="../../assets/img/jts.png" alt=""  id="up" v-if="show">
      <img src="../../assets/img/jtx.png" alt=""  id="down" v-if="!show">
    </div>
    <div class="myside" id="testM">
      <div class="con1 cursor-pointer" @click="jumpMynode">{{lang('mynode')}}</div>
      <div class="con1 cursor-pointer" @click="jumpMyvote">{{lang('wodezhiya')}}</div>
      <div class="con1 cursor-pointer" @click="jumpMynft">{{lang('mynft')}}</div>
    </div>
    <div class="con">
      <div  class="d1" @click="showConnectWallet" v-if="$store.state.accounts.length === 0">
            <div class="d11" ></div>
            {{lang('a_lianjieqianbao')}}
          </div>
          <div v-if="$store.state.accounts.length > 0" class="d1" @click="showAccountInfo">
            {{cutAccount($store.state.accounts[0])}}
          </div>
    </div>
    <div class="sec4">
      <!-- <div class="d3" v-if="isOpenType">
        <div class="d_height"></div>
      </div> -->

      <div class="d3" v-if="!isOpenType">
        <div class="d4">
          <a href="https://t.me/trustfi_fans" target="_blank">
          <!-- <img src="../../assets/img/link_github22x.png"/> -->
          <img src="../../assets/img/tl.png"/>
          </a>
        </div>
        <div class="d4">
          <a href="https://twitter.com/trustfiorg" target="_blank">
          <!-- <img src="../../assets/img/link_twitter22x.png"/> -->
          <img src="../../assets/img/tt.png"/>
          </a>
        </div>
        <div class="d4">
          <a href="https://medium.com/@trustfi" target="_blank">
          <!-- <img src="../../assets/img/link_telegram22x.png"/> -->
          <img src="../../assets/img/me.png"/>
          </a>
        </div>
        <div class="d4">
          <a href="https://github.com/TrustFiNetwork" target="_blank">
            <!-- <img src="../../assets/img/link_check2.png"/> -->
            <img src="../../assets/img/di.png"/>
          </a>
        </div>
        <div class="d4">
          <a href="https://www.certik.org/projects/trustfinetwork" target="_blank">
          <!-- <img src="../../assets/img/link_document2.png"/> -->
          <img src="../../assets/img/pro.png"/>
          </a>
        </div>
      </div>
      <!-- <div class="logoB">
        <img src="../../assets/img/logobot.png" alt="">
      </div> -->
    </div>
  </div>
</template>

<script>
  import { lang, Toast } from "@/tools";
  import {cutAccount} from "../../tools";
  import * as config from "../../config";
  import * as metaMaskTools from "../../tools/chain";
  import toolUtils from "../../tools/toolUtils";
  export default {
    name: 'MobileMenu',
    data(){
      return {
        isOpenType: false,
        value:'',
        changeSelectStyle:'',
        isParallelAssets: false,
        pusdtText: '',
        pusdtContract: '',
        pusdtAssets: 0,
        show:false,
        pethText: '',
        pethContract: '',
        pethAssets: 0,
        myInfoObj:0,
        exchangeRateLanguage: ['pusdt', 'peth'],
      }
    },
    methods: {
      openParallelAssets () {
        this.isParallelAssets = true
        this.queryParallelAssets()
      },
      closeDia () {
        this.isParallelAssets = false
      },
      showOrHide(){
        this.show=!this.show
        var showState = document.getElementById("testM").style.display;
          if (showState == "none") {
              document.getElementById("testM").style.display = "block";
          } else {
              document.getElementById("testM").style.display = "none";
          }
        },
      async getNodeMyInfo() {
        var res = await metaMaskTools.getNodeMyInfo(this.account);
        this.myInfoObj=res.depositAmount
      },
       jumpMynode() {
        if(this.myInfoObj==0){
          Toast(this.lang('meiyouchuangjianjiedian'))
        } else {
          this.$router.push({  name: 'mynode',params:{flagShowVal:false}});  
            this.$store.commit('setState', {
          mobileMenuShow: false
        })
        }
      },
      jumpMyvote() {
        this.$router.push('/myVote');
          this.$store.commit('setState', {
          mobileMenuShow: false
        })
      },
      jumpMynft() {
        this.$router.push('/myNft');
          this.$store.commit('setState', {
          mobileMenuShow: false
        })
      },
      cutAccount(account){
        return cutAccount(account);
      },
      showConnectWallet(){
        this.$store.commit('setState', {
          connectWalletShow: true
        })
      },
      showAccountInfo(){
        this.$store.commit('setState', {
          accountInfoShow: !this.$store.state.accountInfoShow
        })
      },
      onlanguagechange (type) {
        this.changeSelectStyle = type;
        this.$store.commit('setState', {
          languageType: type
        })
        this.$store.commit('setState', {
          mobileMenuShow: false
        })
      },
     
      lang(key){
        return lang(key);
      },
      JumpNode() {
        this.$router.push('/node');
          this.$store.commit('setState', {
          mobileMenuShow: false
        })
      },
      JumpNft() {
        this.$router.push('/nftmining');
          this.$store.commit('setState', {
          mobileMenuShow: false
        })
      },
    },
  }

</script>

<style lang="less">
  .MobileMenu {
    position: fixed;
    right: 0;
    top: 45px;
    height: 100%;
    width: 1.5rem;
    padding: 40.5px 0.15rem 0;
    background: #0F111F;
    color: #fff;
    font-size: 12px;
    z-index: 998;
    .img1{
      position: absolute;
      right: 0.04rem;
      top: -6px;
    }
    .sec1 {
      text-align: center;
      > img {
        width: 0.5rem;
        height: 37.5px;
      }
    }
    .changeStyle{
        font-size: 12px;
        color: #FFE000;
    }
    .lang {
      margin: 0 auto 30px;
      width: 0.55rem;
      height: 15px;
      line-height: 16px;
      background:linear-gradient(rgba(#0077C5,.3) , rgba(#0045BB,.3));
      font-size: 12px;
      border-radius: 10px;
      color: #BAC2C5;
      text-align: center;
    }
    .node {
      display: flex;
      justify-content: space-between;
      width: 1.2rem;
      height: 32.5px;
      border-radius: 10px;
      padding: 8.5px 0.25rem 8px 0.135rem;
      color: #fff;
      font-size: 12px;
      margin-bottom: 20px;
      .no {
        margin-top: 1.5px;
      }
    }
    .node:hover {
      background-color: #000007;
    }
    .min {
      padding: 8.5px 0.17rem 8px 0.135rem;
      .mining {
        margin-top: 1.5px;
      }
    }
    .min:hover {
      background-color: #000007;
    }
    .my {
      color: #fff;
      margin-bottom: 2px;
      padding: 8.5px 25px 8px 0.135rem;
      #up,#down {
        display: inline-block;
        width: 6.5px;
        height: 6.5px;
        margin-top: 5px;
      }
    }
    .min:hover {
      background-color: #000007;
    }
    .myside {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align-last: left;
      color: #fff;
      font-size: 12px;
      padding-left: 0.32rem;
      .con1 {
        padding: 0 5px 0 10px;
        height: 32.5px;
        line-height: 32.5px;
        border-radius: 5px;
      }
      .con1:hover {
         background-color: #000007;
      }
    }
  .con {
    position: fixed;
    bottom: 90px;
    width: 1.2rem;
    .d1{
        margin-top: 0.5rem;
        width: 100%;
        height: 30px;
        background-color: #fff;
        color:#032A93 ;
        border-radius: 10px;
        font-size: 10px;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        .d11 {
          width: 10.5px;
          height: 10.5px;
          background: url('../../assets/img/qb.png') no-repeat;
          background-size: 100% 100%;
          display: inline-block;
          margin-right: 5px;
        }
      }
  }
  
    .sec4 {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 30px 19.5px 70px 19.5px;

      .d1 {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 30px;
      }

      .d2 {
        margin-right: 20px;
        cursor: pointer;

        img {
          width: 30px;
          height: 30px;
        }
      }

      .d3 {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
      .logoB {
        margin-top: 30px;
        text-align: center;
        img {
          width: 75px;
          height: 20px;
        }
      }

      .d4 {
        cursor: pointer;

        img {
          width: 17.5px;
          height: 17.5px;
        }

        img:nth-of-type(1) {
          display: block;
        }

        img:nth-of-type(2) {
          display: none;
        }
      }

      .d4:hover {
        img:nth-of-type(1) {
          display: none;
        }

        img:nth-of-type(2) {
          display: block;
        }
      }
    }
  }
  @media (max-width: 768px){
    .MobileMenu .sec2 {
      z-index: 9;
      position: relative;
    }
    .MobileMenu .m_parallel_assets .m_d_button {
      height: 80px;
      position: relative;
      top: -52px;
    }
    .MobileMenu .m_parallel_assets .m_d_button em {
      position: relative;
      top: 40px;
    }
  }
</style>
