<template>
  <!--投票弹窗-->
  <div class="Vote">
    <van-dialog
      v-model="$store.state.myVoteShow"
      show-cancel-button
      :showConfirmButton="false"
      :showCancelButton="false"
      title=""
    >
      <div class="wrap">
        <div class="sec1">
          <div class="d1">{{ lang("pleFor") }}【{{cutNamePopup(myInfoObj.name)}}】</div>
          <img
            @click="hide"
            class="img1"
            src="../../assets/img/btn_close2x.png"
          />
          <div class="d2 mb15">
            <!-- <div class="d3">{{ lang("toupiaoshuliang") }}</div> -->
            <div class="d3">
              {{ lang("a_keyong") }}：
              <div class="d4">{{ BigNumberStr(nftNum, 0, 3) }}TFI</div>
            </div>
          </div>
          <div class="d5">
            <input v-model="voteAmount" placeholder="" />
            <div class="d6" @click="voteAmountShow">
              {{ lang("a_zuida") }}
            </div>
          </div>

          <div class="d8">
            <!-- v-if="voteAmount*1 <= nftNum/1" -->
            <button
              class="active"
              v-if="getAllowanceNum <= 0"
              @click="getNodeApprove"
            >
              {{ lang("a_shouquan") }}
            </button>
            <button class="active .cursor-pointer" v-if="getAllowanceNum > 0" @click="submit">
              {{ lang("queding") }}
            </button>
            <!-- <button class="active1" v-else>{{lang('a_queren')}}</button> -->
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {lang,Toast,} from "../../tools";
import {cutNamePopup} from "../../tools/index";
import * as metaMaskTools from "../../tools/chain";
import { getNetValue, redemptionIns, subscribeIns } from "../../tools/chain";
import { mixin1, mixin2, mixin3 } from "../../tools/mixins";
import { busEvent } from '../busEvent.js'

export default {
  name: "Vote",
  mounted() {},
  mixins: [mixin1, mixin2, mixin3],
  created() {
  },
  data() {
    return {
      show:false,
      voteAmount: "",
      getAllowanceNum: 0,
      tabIndex: 0,
      nftNum: 0,
      nodeAddress: [],
      myInfoObj:{
        rate:0,
        totalAmount:0,
        voteAmount:0,
        depositAmount:0,
        name:'',
        slogan:'',
      },
    };
  },
  computed: {
    account() {
      return this.$store.state.accounts[0];
    },
    selectItem() {
      return this.$store.state.selectItem;
    },
    selectItemAtatus() {
      return this.$store.state.selectItemAtatus;
    },
    selectName() {
      return this.$store.state.selectName;
    },
    selectVotecanDeposit() {
      console.log(this.$store.state.selectVotecanDeposit,6666666);
      return this.$store.state.selectVotecanDeposit;
      
    }
  },
  methods: {
    initPage() {
      this.getNodeMyInfo()
      this.getAllowance()
      this.getbalanceOfNFT()
    },
    initData() {},
    onRefresh(accounts) {
      this.initPage();
    },
    lang(key) {
      return lang(key);
    },
    cutNamePopup(name){
        return cutNamePopup(name);
    },
    async getNodeMyInfo() {
      var res = await metaMaskTools.getNodeMyInfo(this.account);
      this.myInfoObj=res
    },
    getNetValue() {
      if (getNetValue() <= 0) {
        return 1;
      }
      return getNetValue();
    },
    hide() {
      this.$store.commit("setState", {
        myVoteShow: false,
      });
    },
    async getAllowance() {
      var res = await metaMaskTools.getNodeAllowance(this.account);
      this.getAllowanceNum = res;
    },
    async getNodeApprove() {
      await metaMaskTools.getNodeApprove(this.account);
      Toast(this.lang("a_shouquanchenggong"));
      this.getAllowance();
    },
    async getbalanceOfNFT() {
      var res = await metaMaskTools.getbalanceOfNFT(this.account);
      this.nftNum = this.BigNumberStr(res, 18);
    },
    voteAmountShow() {
      this.voteAmount=this.nftNum
      this.voteAmount=this.BigNumberStr(this.voteAmount,0,3)

    },
    async submit() {
      // this.voteAmount=this.BigNumberStr(this.voteAmount,0,3)
      if(this.selectItemAtatus==0) {
        Toast(this.lang('bukegeigaijeidiantoupiao'))
        this.hide();
        return
      } else {
        if(this.voteAmount=='' ||this.voteAmount<0  ) {
          Toast(this.lang('qingshuruzhengqueshuzhi'))
          return
        } else {
          if(this.voteAmount/1>this.nftNum/1) {
          Toast(this.lang('zhanghuyuebuzu'))
          return
        } else {
          var res=await metaMaskTools.getNodeVote(this.account, this.voteAmount);
           Toast(this.lang("pleSucc"));
          this.voteAmount=''
          this.hide();
          busEvent.$emit('getBusMyNodeVote',true)
        }
        }
        
        
      }
    },
  },
};
</script>

<style lang="less">
.cursor-pointer{
  cursor: pointer;
}
.Vote {
  letter-spacing: 3px;

  .van-dialog {
    width: 360px;
  }

  .wrap {
    > .sec1 {
      background: #152e55;
      border-radius: 4px;
      padding: 0 30px 30px;
      position: relative;
      height: auto;
      overflow: hidden;
      border: 0;
      > .img1 {
        width: 12px;
        height: 12px;
        cursor: pointer;
        position: absolute;
        right: 25px;
        top: 20px;
      }

      .d1 {
        font-weight: 600;
        font-size: 14px;
        color: #fff;
        height: 70px;
        line-height: 70px;
        text-align: center;
        margin: 30px 0 20px;
      }

      .d2 {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      .d3 {
        font-size: 12px;
        color: #ccc;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      .d4 {
        color: #fff;
      }
      .d5 {
        position: relative;
        margin-bottom: 10px;
        input {
          width: 100%;
          height: 50px;
          line-height: 50px;
          font-size: 24px;
          color: #fff;
          padding: 0 20px;
          border: solid 1px #0f111f;
          background: #0f111f;
          border-radius: 10px;
        }
      }

      .d6 {
        color: #fff;
        font-size: 12px;
        background: linear-gradient(#0077c5, #0045bb);
        text-align: center;
        padding: 4px 12px;
        position: absolute;
        right: 10px;
        top: 13px;
        border-radius: 8px;
        cursor: pointer;
      }

      .d7 {
        font-size: 14px;
        color: #fff;
        position: absolute;
        right: 20px;
        top: 21px;
      }

      .d8 {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        // button {

        // }

        button.active {
          width: 100px;
          height: 25px;
          border: 0;
          text-align: center;
          line-height: 25px;
          font-size: 12px;
          color: #fff;
          background: linear-gradient(#0077c5, #0045bb);
          border-radius: 10px;
          margin: 0 18px;
        }

        // button.active1 {
        //   background: linear-gradient(#0077c5, #0045bb);
        //   border: 0;
        //   color: #fff;
        //   border-radius: 10px;
        // }
      }
    }
  }
}

@media (max-width: 768px) {
  .Vote {
    .van-dialog {
      width: 300px;
    }

    .wrap {
      > .sec1 {
        padding: 0 20px 15px;

        .img1 {
          right: 21px;
          top: 16px;
        }

        .d1 {
          padding: 0 0 0 20px;
          height: 45px;
          line-height: 45px;
          font-size: 13px;
          margin: 30px 0 20px;
        }

        .d2 {
          margin: 20px 0 10px;
        }

        .d2_1 {
          margin-bottom: 25px;
        }

        .d5 {
          position: relative;
          background: #17191c;
          margin-bottom: 10px;
          border-radius: 10px;
          input {
            height: 40px;
            line-height: 40px;
            padding: 0 10px;
          }
        }
        .d6 {
          top: 8px;
        }
        .d7 {
          top: 11px;
        }
        .d6,
        .d7,
        .d8,
        .d9,
        .d10 {
          font-size: 10px;
        }
        .d8 {
          button {
            height: 40px;
            line-height: 40px;
            font-size: 13px;
          }
        }
      }
    }
  }
}
.line_tc {
  margin-bottom: 20px;
  background: #fff;
  height: 1px;
}
</style>
